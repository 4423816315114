<template>
  <view-base ref="base">
    <div v-if="userAdmin">
      <request-delivery-card :stores="stores" @started="merchantCallDriver" />
      <div v-if="ordersOnTransit.length > 0">
        <h4>
          <b>{{ $t('Pedidos em entrega') }}</b> ({{ ordersOnTransit.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersOnTransit" :key="order.id" :order="order" :merchant-mode="userAdmin" :can-delivery="ordersAccepted.length === 0"
            @delivered="onOrderFinished"
          />
        </div>
      </div>
      <div v-if="ordersAccepted.length > 0">
        <h4>
          <b>{{ $t('Pedidos aceitos') }}</b> ({{ ordersAccepted.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersAccepted" :key="order.id" :order="order" :merchant-mode="userAdmin"
            @accepted="onOrderAccepted" @taked="onOrderTaked" @canceled="onOrderCanceled" @backToReady="onOrderBackToReady"
          />
        </div>
      </div>
      <div v-if="ordersOnPool.length > 0">
        <h4>
          <b>{{ $t('Pedidos novos') }}</b> ({{ ordersOnPool.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersOnPool" :key="order.id" :order="order"
            :merchant-mode="userAdmin"
            @canceled="onOrderCanceled" @backToReady="onOrderBackToReady"
          />
        </div>
      </div>
      <div>
        <h4>
          <b>{{ $t('Pedidos entregues hoje') }}</b> ({{ ordersToday.length }})
        </h4>
      </div>
    </div>

    <div v-if="!userAdmin">
      <driver-self-start-card v-if="1 == 1 && stores.length > 0 && ((ordersAccepted.length + ordersToTake.length + ordersOnTransit.length) < maxOrdersTakeSameTime)" :stores="stores" @started="onDriverSelfDeliveryStarted" />
      <div v-if="ordersOnTransit.length > 0">
        <h4>
          <b>{{ $t('Pedidos em entrega') }}</b> ({{ ordersOnTransit.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersOnTransit"
            :key="order.id" :order="order" :can-delivery="ordersAccepted.length === 0"
            @delivered="onOrderFinished"
            @canceled="onDeliveryCanceled"
          />
        </div>
      </div>
      <div v-if="ordersAccepted.length > 0">
        <h4><b>{{ $t('Pedidos aceitos') }}</b> ({{ ordersAccepted.length }})</h4>
        <div class="row">
          <order-card
          v-for="order in ordersAccepted" :key="order.id" :order="order" @accepted="onOrderAccepted" @taked="onOrderTaked"
          @backToReady="onOrderBackToReady" />
        </div>
      </div>
      <div v-if="ordersPreparing.length > 0">
        <h4 @click="toggleOrdersPreparing">
          <b>{{ $t('Pedidos em preparo') }}</b> ({{ ordersPreparing.length }})
        </h4>
        <div v-if="ordersPreparingVisible" class="row">
          <order-card
            v-for="order in ordersPreparing" :key="order.id" :order="order"
            @accepted="onOrderAccepted" @taked="onOrderTaked" @backToReady="onOrderBackToReady"
          />
        </div>
      </div>
      <div v-if="ordersReady.length > 0">
        <h4>
          <b>{{ $t('Pedidos prontos') }}</b> ({{ ordersReady.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersReady" :key="order.id" :order="order"
            @accepted="onOrderAccepted" @taked="onOrderTaked"
          />
        </div>
      </div>
      <div v-if="ordersOnPool.length > 0 && ((ordersAccepted.length + ordersToTake.length) < maxOrdersTakeSameTime)">
        <h4>
          <b>{{ $t('Pedidos disponíveis') }}</b> ({{ ordersOnPool.length }})
        </h4>
        <div class="row">
          <order-card
            v-for="order in ordersOnPool"
            :key="order.id" :order="order"
            :super-user="userEmail == 'danilo@eatz.pt'"
            @accepted="onOrderAccepted"
            @taked="onOrderTaked"
            @canceled="onOrderCanceledSuperuser" @backToReady="onOrderBackToReady"
          />
        </div>
      </div>
      <div>
        <h4>
          <b>{{ $t('Pedidos entregues hoje') }}</b> ({{ ordersToday.length }})
        </h4>
      </div>
    </div>
  </view-base>
</template>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import Ripple from 'vue-ripple-directive'

import axios from 'axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'
import DriverSelfStartCard from '@/views/components/DriverSelfStartCard.vue'
import OrderCard from '@/views/components/OrderCard.vue'
import RequestDeliveryCard from '@/views/components/RequestDeliveryCard.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ViewBase,
    DriverSelfStartCard,
    OrderCard,
    RequestDeliveryCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      maxOrdersTakeSameTime: 3,
      ordersPreparingVisible: false,
      ordersPreparing: [],
      ordersReady: [],
      ordersAccepted: [],
      ordersToday: [],
      ordersOnTransit: [],
      ordersToTake: [],
      ordersOnPool: [],
      geoLocationUpdated: false,
      geolocation: { lat: 0, lon: 0 },
      userAdmin: getUserData().is_super_admin === 1,
      userEmail: getUserData().email,
      storeId: getUserData().store_id,
      stores: [],
      columns: () => {
        const columns = [
          {
              title: 'Código',
              name: 'code',
              visible: true,
              sortable: true,
              cellstyle: 'table-cell-bold-sigla w-40px',
              filtered: false,
          },
          {
              title: 'Hora',
              name: 'formatDate',
              visible: true,
              sortable: true,
              cellstyle: 'table-cell-bold-sigla w-40px',
          },
        ]
        if (this.userAdmin) {
          columns.push({
              title: 'Valor',
              name: 'deliveryCost',
              visible: true,
              sortable: true,
              cellstyle: 'table-cell-bold-sigla w-40px',
          })
        }
        if (!this.userAdmin) {
          columns.push({
              title: 'Restaurante',
              name: 'store',
              visible: true,
              sortable: true,
              cellstyle: 'table-cell-bold-sigla w-40px',
          })
          columns.push({
              title: 'Valor',
              name: 'driverAmount',
              visible: true,
              sortable: true,
              cellstyle: 'table-cell-bold-sigla w-40px',
          })
        }
        return columns
      },
      sortColunms: [],
      filteredSize: 0,
      onSelectData(event, data) {
        this.$router.push({ name: 'admin-stores-edit', params: { id: data.id } })
      },
    }
  },
  mounted() {
    this.driverLoadAllStores()
    if (!this.userAdmin) {
      this.showCurrentLocation()
      window.OrdersEvent.listen('Orders', this.newOrderReceived)
    }
    this.loadData()
  },
  created() {
  },
  methods: {
    newOrderReceived(data) {
      console.log('newOrderReceived', data?.newOrderReceived, 'order_id', data?.order_id)
      this.loadData()
      // this.playSound('/audios/new_delivery.mp3')
    },
    showCurrentLocation() {
      const self = this
      window.GPSWatches.push(
        navigator.geolocation.watchPosition(
          position => {
            self.geolocation.lat = position.coords.latitude
            self.geolocation.lon = position.coords.longitude
            if (!self.geoLocationUpdated) self.driverSentLocation()
          },
          error => {
            console.log(error.message)
            this.$refs.base.showToastError(this.$t('Não foi possível detectar sua localização, tente aceder novamente a plataforma e aceite para partilhar a sua localização'))
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          },
        ),
      )
    },
    loadData() {
      const self = this
      this.$refs.base.showLoading()

      if (this.userAdmin) {
        const loadOrdersOnProgress = self.merchantLoadOrdersOnProgress()
        const loadOrdersOnTransit = self.merchantLoadOrdersOnTransit()
        const loadOrdersDeliveredToday = self.merchantLoadOrdersDeliveredToday()

        axios.all([loadOrdersOnProgress, loadOrdersOnTransit, loadOrdersDeliveredToday])
        .then(axios.spread(() => {
          this.$refs.base.hideLoading()
        }))
        .catch(errors => {
          console.error(errors)
        })
      } else {
        self.driverSentLocation()
        const loadOrdersOnProgress = self.driverLoadOrdersOnProgress()
        const loadOrdersDeliveredToday = self.driverLoadOrdersDeliveredToday()

        axios.all([loadOrdersOnProgress, loadOrdersDeliveredToday])
        .then(axios.spread(() => {
          this.$refs.base.hideLoading()
        }))
        .catch(errors => {
          console.error(errors)
        })
      }
    },
    onDriverRequested() {
        this.$refs.base.showToast(this.$t('Estafeta solicitado com sucesso'))
        this.loadData()
    },
    onDriverRequestedError(data, error) {
        console.log('error', error)
        this.$refs.base.showToastError(this.$t(error?.data?.message ?? 'Não foi possível completar sua requisição, tente novamente'))
    },
    driverLoadOrdersOnProgress() {
      const self = this

      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/onProgress`)
      .then(response => {
        const orders = response.data.newOrders ?? []

        orders.forEach((order, index) => {
          orders[index].created = moment(orders[index].created_at).format('HH:mm')
        })

        self.ordersPreparing = orders.filter(order => moment(order.estimated_ready_for_pickup_at) > moment())
        self.ordersAccepted = response.data.acceptedOrders ?? []
        self.ordersReady = response.data.readyOrders ?? []
        self.ordersOnPool = orders.filter(order => order.estimated_ready_for_pickup_at == null || order.estimated_ready_for_pickup_at === '0000-00-00 00:00:00' || moment(order.estimated_ready_for_pickup_at) < moment())
        self.ordersOnTransit = response.data.onDeliveryOrders ?? []
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    driverLoadOrdersDeliveredToday() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/today`)
      .then(response => {
        self.ordersToday = response.data.orders ?? []
        self.ordersToday.forEach((order, index) => { self.ordersToday[index].formatDate = moment(self.ordersToday[index].delivered_time).format('HH:mm DD/MM/YYYY') })
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    driverSentLocation() {
      if (this.geolocation.lat !== 0) {
        useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/geo`, { lat: this.geolocation.lat, lon: this.geolocation.lon })
        this.geoLocationUpdated = true
      }
    },
    driverLoadAllStores() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/list`)
      .then(response => {
        self.stores = response.data.stores ?? []
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },

    merchantLoadOrdersOnProgress() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/onProgress`)
      .then(response => {
        const orders = response.data.newOrders ?? []

        orders.forEach((order, index) => {
          orders[index].created = moment(orders[index].created_at).format('HH:mm')
        })

        self.ordersPreparing = orders.filter(order => moment(order.estimated_ready_for_pickup_at) > moment())
        self.ordersOnPool = orders.filter(order => order.estimated_ready_for_pickup_at == null || moment(order.estimated_ready_for_pickup_at) < moment())
        self.ordersOnTransit = response.data.onDeliveryOrders ?? []
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    merchantLoadOrdersOnWaiting() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/onPool`)
      .then(response => {
        self.ordersOnPool = response.data.orders ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    merchantLoadOrdersOnTransit() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/onTransit`)
      .then(response => {
        self.ordersOnTransit = response.data.orders ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    merchantLoadOrdersDeliveredToday() {
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/today`)
      .then(response => {
        self.ordersToday = response.data.orders ?? []
        self.ordersToday.forEach((order, index) => { self.ordersToday[index].formatDate = moment(self.ordersToday[index].delivered_time).format('HH:mm DD/MM/YYYY') })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
    },
    merchantCallDriver(data) {
      console.log('callDriver', data)
      const self = this
      this.$refs.base.showLoading()
      self.onResquesting = true
      useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/create`, data)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não foi criado'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido criado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.message))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderAccepted(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/accept`, this.geolocation)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não disponível'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido atualizado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderTaked(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/start`, this.geolocation)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não disponível'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido atualizado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderFinished(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/finish`, this.geolocation)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t(response.data.error ?? 'Pedido não disponível'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido finalizado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onDeliveryCanceled(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/cancel`, this.geolocation)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não disponível'))
        } else {
          this.$refs.base.showToast(this.$t('Entrega cancelada'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderBackToReady(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/goBackDelivery`, this.geolocation)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não disponível'))
        } else {
          this.$refs.base.showToast(this.$t('Entrega devolvida'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderCanceled(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/${order.id}/cancelDelivery`)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Não foi possível realizar o cancelamento'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido cancelado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onOrderCanceledSuperuser(order) {
      this.$refs.base.showLoading()
      const self = this
      return useJwt.axiosIns.get(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/${order.id}/cancelDeliverySuperuser`)
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Não foi possível realizar o cancelamento'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido cancelado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        self.loadData()
      })
    },
    onDriverSelfDeliveryStarted(order) {
      const self = this
      this.$refs.base.showLoading()
      return useJwt.axiosIns.post(`${process.env.VUE_APP_CORE_ENDPOINT}/orders/start_self_delivery`, {
        lat: this.geolocation.lat, lon: this.geolocation.lon, order: order.number, store: order.store,
        })
      .then(response => {
        if (response.data.result !== true) {
          this.$refs.base.showToastError(this.$t('Pedido não foi criado'))
        } else {
          this.$refs.base.showToast(this.$t('Pedido criado'))
        }
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.message))
      })
      .finally(() => {
        self.loadData()
      })
    },
    toggleOrdersPreparing() {
        this.ordersPreparingVisible = !this.ordersPreparingVisible
    },
    playSound(sound) {
      if (sound) {
        const audio = new Audio(sound)
        audio.play()
      }
    },
  },
}
</script>
