<template>
    <div class="row conteiner my-2">
      <div class="col-12 text-center">
        <button v-if="!showActions" class="btn btn-primary" type="button" @click="chanceView">SOLICITAR ESTAFETA</button>
        <div v-if="showActions" class="card">
            <div class="card-body row">
                <div class="col">
                  <b-form @submit.prevent @submit="callDriver">
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Restaurante">
                          <select
                            v-model="store"
                            :class="{ 'form-control': true }"
                            :required=true
                          >
                            <option
                              v-for="option in stores"
                              :label="option.name"
                              :key="option.id"
                              :value="option.id"
                            >{{ option.text }}</option>
                          </select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Código do pedido">
                          <b-form-input v-model="orderCode" autocomplete="off"/>
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col md="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="primary"
                          class="mr-1"
                          :disabled="buttonRequestDisabled"
                        >
                          Save
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          @click="cancelRequest"
                          variant="outline-secondary"
                        >
                          Cancel
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import * as bootstrapVue from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow: bootstrapVue.BRow,
    BCol: bootstrapVue.BCol,
    BFormGroup: bootstrapVue.BFormGroup,
    BFormInput: bootstrapVue.BFormInput,
    BForm: bootstrapVue.BForm,
    BButton: bootstrapVue.BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showActions: false,
      onResquesting: false,
      orderCode: '',
      store: 0,
    }
  },
  computed: {
    buttonRequestDisabled() {
      return this.onResquesting || this.orderCode?.length <= 3
    },
  },
  props: {
    stores: { type: Array, default: () => ([]) },
  },
  mounted() {
    this.reset()
  },
  created() {
  },
  methods: {
    reset() {
      this.onResquesting = false
      this.showActions = false
      this.orderCode = ''
      this.store = 0
    },
    callDriver() {
      this.$emit('started', {
        orderCode: this.orderCode.toUpperCase(),
        storeId: this.store,
      })
      this.reset()
    },
    chanceView() {
      this.showActions = !this.showActions
    },
    cancelRequest() {
      this.chanceView()
      this.reset()
    },
  },
}
</script>
