<template>
  <div class="col-md-12">
    <div class="card">
      <button v-if="!showActions" class="btn btn-primary px-4 py-1" @click="chanceView">
        {{ $t('Iniciar entrega') }}
      </button>
      <div v-if="showActions" class="card-body row">
        <div class="col-12">
          <div class="form-group">
            <label><b>{{ $t('Restaurante') }}:</b></label>
            <select
              v-model="store"
              :class="{ 'form-control': true }"
              :required="true"
            >
              <option
                v-for="option in stores"
                :key="option.id"
                :label="option.name"
                :value="option.id"
                :selected="option.id == store"
              >{{ option.text }}</option>
            </select>
          </div>

          <input
            v-model="orderNumber"
            type="text"
            :class="{ 'form-control': true }"
            :placeholder="$t('Código do pedido')"
            autocomplete="false"
            autocapitalize="none"
            autocorrect="off"
            required
            @input="updateCode"
          >
        </div>
        <div class="col-12 d-flex justify-content-center mt-2">
          <button class="btn btn-primary col-5 mr-2" :disabled="orderNumber.length < 4" @click="started">
            OK
          </button>
          <button class="btn btn-danger col-5" @click="chanceView">
            {{ $t('CANCELAR') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stores: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      showActions: false,
      orderNumber: '',
      store: 0,
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    reset() {
      this.showActions = false
      this.orderNumber = ''
    },
    updateCode() {
      this.orderNumber = this.orderNumber.toUpperCase()
    },
    chanceView() {
      this.showActions = !this.showActions
      if (this.store === 0) this.store = this.stores[0].id ?? 0
    },
    started() {
      this.$emit('started', { number: this.orderNumber, store: this.store })
      this.reset()
    },
  },
}
</script>
