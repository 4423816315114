<template>
  <div class="col-md-3">
    <div class="card ordercard">
      <div class="card-body orderCard rounded" v-bind:class="getClass()" @click="chanceView">
        <div class="row ">
          <div class="pull-left text-center">
            <IntegratorIcon :integrator="order.integrator" />
            <br>
            <span style="font-size:20px;color:white;">{{ order.code }}</span>
          </div>
          <div class="col">
            <span><feather-icon icon="UserIcon" /> {{ order.eater_name }}</span>
            <br>
            <span><feather-icon icon="InfoIcon" /> {{ order.store.name }}</span>
            <br>
            <span><feather-icon icon="ClockIcon" /> {{ order.created_at | formatDate }}</span>
            <br>
            <span v-if="order.delivery_obs.length > 0"><feather-icon icon="InfoIcon" /> {{ order.delivery_obs }}<br></span>
            <span v-if="1 == 2 && order.driver_amount > 0">{{ order.driver_amount | currency('EUR') }}</span>
          </div>
          <div class="col pull-right" style="{'z-index': 20}">
            <b-button class="rounded-circle" style="background-color:green !important;" @click.stop="showInfo(order)">
              <feather-icon icon="PhoneIcon" /><br/>{{ $t('orders.call') }}
            </b-button>
            <b-button v-if="!showActions && (order.eater_address != null && order.eater_address != '')" class="rounded-circle" @click.stop="callWaze(order.eater_address+','+order.eater_address_number+' - '+order.eater_zipcode)">
              <feather-icon icon="Navigation2Icon" /><br/>{{ $t('orders.maps') }}
            </b-button>
            <span v-if="showActions" class="">
              <button v-if="showActions && merchantMode == false && (order.status == 'NEW' || order.status == 'READY')" class="btn btn-primary no-pad-b rounded-circle" @click.stop="accepted">
                <feather-icon icon="OkIcon" /><br/>Aceitar
              </button>
              <button v-if="showActions && merchantMode == false && (order.status == 'ACCEPTED')" class="btn btn-primary no-pad-b rounded-circle" @click.stop="taked">
                <feather-icon icon="PlayIcon" /><br/>Recolher
              </button>
              <button v-if="showActions && merchantMode == false && order.status == 'ON_DELIVERY'" class="btn btn-primary no-pad-b rounded-circle" @click.stop="delivered">
                <feather-icon icon="PlayIcon" /><br/>Entregue
              </button>
              <button v-if="showActions && merchantMode == false && (order.status == 'ON_DELIVERY')" class="btn btn-danger no-pad-b rounded-circle" @click.stop="cancelOrder">
                <feather-icon icon="XIcon" /><br/>Cancelar
              </button>
              <button
                v-if="showActions && order.status == 'ACCEPTED'"
                class="btn btn-danger no-pad-b rounded-circle"
                @click.stop="backToReadyOrder"
              >
                <feather-icon icon="XIcon" /><br/>Devolver
              </button>
              <button
                v-if="showActions && (order.status == 'NEW' || order.status == 'ACCEPTED') && (superUser == true || merchantMode == true)"
                class="btn btn-danger no-pad-b rounded-circle"
                @click.stop="cancelOrder"
              >
                <feather-icon icon="XIcon" /><br/>Cancelar
              </button>
            </span>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col">
            <div v-if="merchantMode == true && (order.driver_id != null && order.driver_id != '')">
              <b>{{ $t('Estafeta') }}:</b><span> {{ order.driver.name }} {{ order.driver.lastname.substring(0, 1) }}.</span><br>
            </div>
            <div v-if="(order.eater_address != null && order.eater_address != '')">
              <feather-icon icon="MapPinIcon" /> {{ order.eater_address.trim() }} <b>N:</b> {{ order.eater_address_number }} {{ order.eater_appartment_number }} {{ order.eater_zipcode }} {{ order.address != null && order.address.area_level_2 != null ? order.address.area_level_2 : '' }} {{ order.address != null && order.address.area_level_3 != null ? order.address.area_level_3 : '' }}

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as bootstrapVue from 'bootstrap-vue'
  import IntegratorIcon from './IntegratorIcon'

export default {
  components: {
    BButton: bootstrapVue.BButton,
    IntegratorIcon,
  },
  props: {
    merchantMode: { type: Boolean },
    order: { type: Object, default: () => ({}) },
    status: { type: String, default: 'NEW' },
    canDelivery: { type: Boolean },
    superUser: { type: Boolean },
  },
  data() {
    return {
      showActions: false,
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    getClass() {
      return {
        order_new: this.order.status === 'NEW',
        order_ready: this.order.status === 'READY',
        order_accepted: this.order.status === 'ACCEPTED',
        order_delivering: this.order.status === 'ON_DELIVERY',
      }
    },
    chanceView() {
      this.showActions = !this.showActions
    },
    accepted() {
      this.$emit('accepted', this.order)
    },
    taked() {
      this.$emit('taked', this.order)
    },
    delivered() {
      if (window.confirm(`Confirma a entrega do pedido ${this.order.code}?`)) {
        this.$emit('delivered', this.order)
      }
    },
    cancelOrder() {
      this.$emit('canceled', this.order)
    },
    backToReadyOrder() {
      this.$emit('backToReady', this.order)
    },
    callWaze(address) {
      window.open(`waze://?q=${address}&navigate=yes`)
    },
    showInfo(order) {
      if (order.integrator === 'eatz') {
        window.open(`tel:${order.eater_phone}`)
      } else {
        alert(`${this.$t('Pedido')}: ${order.code}\n${this.$t('Criado')}: ${order.created_at}\n${this.$t('Cliente')}: ${order.eater_name}\n${this.$t('Telefone')}: ${order.eater_phone}`)
      }
    },
  },
}
</script>
