<template>
  <BImg v-if="!(!integrator || integrator == undefined || integrator == '')" :src="icon" width="50" :style="{opacity: 1}" rounded="circle" />
</template>

<script>
  import * as bootstrapVue from 'bootstrap-vue'

  export default {
    name: 'IntegratorIcon',
    components: {
      BImg: bootstrapVue.BImg,
    },
    props:
    {
      integrator: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        icon: null,
      }
    },

    mounted() {
      switch (this.integrator) {
        case 'eatz':
          this.icon = require('@/assets/images/icons/eatz.png')
          break
        case 'uber':
          this.icon = require('@/assets/images/icons/ubereats.svg')
          break
        case 'glovo':
          this.icon = require('@/assets/images/icons/glovo.png')
          break
        case 'boltfood':
          this.icon = require('@/assets/images/icons/boltfood.png')
          break
        case 'zomato':
          this.icon = require('@/assets/images/icons/zomato.png')
          break
        default:
          break
      }
    },

    methods: {
    },
  }
</script>
